@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.dash-card {
  background-image: linear-gradient(319deg, #63b509, #b6e683);
}

.info-card1 {
  background-image: linear-gradient(301deg, #096531, #00a146);
}

.info-card2 {
  background-image: linear-gradient(121deg, #8dc63f, #579700);
}

.fav-card {
  background-image: linear-gradient(296deg, #fbccab, #fce2b7);
}

.modal {
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.show-modal {
  transition: 0.3s ease-out;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #efeded;
  border-radius: 50%;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #efeded;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked + .checkmark {
  background-color: #fff;
  border: 2px solid #1b453e;
}

input[type="checkbox"]:checked + .checkmark::after {
  display: block;
  background-color: #1b453e;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: SF-Pro-Display-Regular;
  src: url("./fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Medium;
  src: url("./fonts/SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url("./fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Bold;
  src: url("./fonts/SF-Pro-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Light;
  src: url("./fonts/SF-Pro-Display-Light.otf") format("opentype");
}
